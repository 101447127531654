/*
 * @Description: 获取公司信息
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-09-19 14:02:36
 */
import request from '@/utils/request'

// 获取公司简介
export function getCompanyInfo(data) {
  return request({
    url: 'front/Information/GetAboutInfo',
    method: 'post',
    data
  })
}
// 获取轮播图列表
export function CarouselGetList(data) {
  return request({
    url: 'front/Carousel/GetList',
    method: 'post',
    data
  })
}

// 获取联系我们
export function GetContactInfo(data) {
  return request({
    url: 'front/Information/GetContactInfo',
    method: 'post',
    data
  })
}

// 帮助中心
export function GetHelptInfo(data) {
  return request({
    url: 'front/Information/GetHelptInfo',
    method: 'post',
    data
  })
}

// 获取网站配置信息
export function SiteSettingWebGetInfo(data) {
  return request({
    url: 'front/SiteSettingWeb/GetInfo',
    method: 'post',
    data
  })
}

// 根据租户获取网站设置配置设置明细
export function SysWebSiteConfigGetDetail(data) {
  return request({
    url: 'admin/SysWebSiteConfig/GetDetail',
    method: 'post',
    data
  })
}

// 获取网站配置信息
export function ProductClassWebGetTree(data) {
  return request({
    url: 'front/ProductClassWeb/GetTree',
    method: 'post',
    data
  })
}

// 获取新品列表
export function GetProductListByDate(data) {
  return request({
    url: 'front/Home/GetProductListByDate',
    method: 'post',
    data
  })
}

// 获取样品折扣/推荐/热销列表
export function GetProductListByDiscount(data) {
  return request({
    url: 'front/Home/GetProductListByDiscount',
    method: 'post',
    data
  })
}

// 获取网站配置列表
export function SiteSettingWebGetSiteList(data) {
  return request({
    url: 'front/SiteSettingWeb/GetSiteList',
    method: 'post',
    data
  })
}

// 获取分类样品列表
export function GetProductListByClass(data) {
  return request({
    url: 'front/Home/GetProductListByClass',
    method: 'post',
    data
  })
}

// 获取视频列表
export function VideoGetList(data) {
  return request({
    url: 'front/Video/GetList',
    method: 'post',
    data
  })
}

// 首页关于我们设置服务接口
export function AboutUsWebGetInfo(data) {
  return request({
    url: 'front/AboutUsWeb/GetInfo',
    method: 'post',
    data
  })
}

// 样品包装服务接口
export function ProductPackWebGetList(data) {
  return request({
    url: 'front/ProductPackWeb/GetList',
    method: 'post',
    data
  })
}
/**
 * 获取上一页或下一页样品资料明细
 */
export function getPageDetail(data) {
  return request({
    url: 'front/ProductBasicWeb/GetPageDetail',
    method: 'post',
    data,
  })
}
// 新增收藏
export function CollectionAdd(data) {
  return request({
    url: 'front/Collection/Add',
    method: 'post',
    data,
  })
}
// 新增收藏
export function CollectionBatchDelete(data) {
  return request({
    url: 'front/Collection/BatchDelete',
    method: 'post',
    data,
  })
}