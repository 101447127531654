/*
 * @Description: 
 * @Author: zml
 * @Date: 2022-12-28 11:12:46
 * @LastEditors: zml
 * @LastEditTime: 2023-03-27 17:00:11
 */
import request from '@/utils/request'


// 获取购物车列表 携带用户id    
export function getCartListApi(data) {
  return request({
    url: 'front/MemberCart/GetCart',
    method: 'post',
    data
  })
}
// 添加商品到购物车 携带用户id
export function addCartsApi(data) {
  return request({
    url: 'front/MemberCart/Add',
    method: 'post',
    data
  })
}
// 删除购物车商品 携带用户id
export function deletCartsApi(data) {
  return request({
    url: 'front/MemberCart/Delete',
    method: 'post',
    data
  })
}
// 批量添加商品 携带用户id
export function batchAddApi(data) {
  return request({
    url: 'front/MemberCart/BatchAdd',
    method: 'post',
    data
  })
}

// 创建询价单
export function createInquiry(data) {
  return request({
    url: 'front/Inquiry/CreateInquiry',
    method: 'post',
    data
  })
}

