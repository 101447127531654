/*
 * @Description: 
 * @Author: pcc
 * @Date: 2023-01-11 18:28:17
 * @LastEditors: zml
 * @LastEditTime: 2023-04-14 17:04:16
 */
import axios from 'axios'
import store from '@/store'
import { templates } from './method'
import { TENANTID } from '@/store/mutation-types'
const request = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60000
})

request.interceptors.request.use(
  config => {
    if (store.state.user.token) {
      config.headers['Authorization'] = `Bearer ${store.state.user.token}`
    }
    config.headers['TenantId'] = TENANTID
    //  339850717954117  288762652057669（aa)  142307070918780 364291232903237
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  response => {
    const { data } = response
    if (data.success) {
      return data
    } else {
      if (data.code === 401) {
        localStorage.removeItem('Token')
        localStorage.removeItem('userInfo')
        location.reload()
        if (templates() === 'themeTemplateFour') {
          location.href = '/login'
        } else {
          location.href = '/home'
        }
      }
      return Promise.reject(data.message)
    }
  },
  err => {
    return Promise.reject(err)
  }
)

export default request
