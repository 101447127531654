import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index"
import { templates } from '@/utils/method'

import homePage from '@/views/home'
import productPage from '@/views/product'
import proDetailPage from '@/views/product/detail'
import cartPage from '@/views/cart'
import orderPage from '@/views/order'
import orderDetailPage from '@/views/order/detail'
import aboutPage from '@/views/about/index'
import contactPage from '@/views/about/contact'
import addressPage from "../views/address"
import newDetail from "@/views/about/newDetail"
import news from "@/views/news"
import newsDetail from "@/views/news/detail"
import video from "@/views/video"
import login from "@/views/login"
import brandDetail from "@/views/brand/detail"
import auditaccount from "@/views/audit" // 欧尚宝审核账号页面
import quality from "@/views/quality" // 质量控制
import oem from "@/views/oem" // OEM/ODM
import support from "@/views/support" // 商业支持
import amazon from "@/views/amazon" // 亚马逊支持
import services from "@/views/services" // 帮助中心
import partnership from "@/views/partnership" // 成为我们的伙伴
import inquiry from "@/views/inquiry" // 提交询问
import productlist from "@/views/product/productlist"
import employ from "@/views/employ"
import marketguidance from "@/views/marketguidance"
import sellersupport from "@/views/sellersupport"
import officelocation from "@/views/officelocation"
import favorite from "@/views/favorite"


Vue.use(VueRouter)
// 解决 NavigationDuplicated: Avoided redundant navigation to current location 报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
let pathName = '/home'
if (templates() === 'themeTemplateFour') {
    pathName = '/login'
} else {
    pathName = '/home'
}

const routes = [{
    path: '/',
    redirect: pathName
}, {
    name: 'home',
    path: '/home',
    component: homePage,
    meta: {
        keepAlive: false
    }
},
{
    name: 'product',
    path: '/product',
    component: productPage,
    meta: {
        keepAlive: true // 开启缓存
    }
},
{
    name: 'productlist',
    path: '/productlist',
    component: productlist,
    meta: {
        keepAlive: true // 开启缓存
    }
},
{
    name: 'proDetail',
    path: '/proDetail',
    component: proDetailPage,
    meta: {
        keepAlive: false
    }
},
{
    name: 'cart',
    path: '/cart',
    component: cartPage,
    meta: {
        keepAlive: false
    }
},
{
    name: 'order',
    path: '/order',
    component: orderPage,
    meta: {
        keepAlive: false
    }
},
{
    name: 'address',
    path: '/address',
    component: addressPage,
    meta: {
        keepAlive: false
    }
},

{
    name: 'orderDetail',
    path: '/order/detail',
    component: orderDetailPage,
    meta: {
        keepAlive: false
    }
},
{
    name: 'about',
    path: '/about',
    component: aboutPage,
    meta: {
        keepAlive: false
    }
},
{
    name: 'contact',
    path: '/contact',
    component: contactPage,
    meta: {
        keepAlive: false
    }
},
{
    name: 'newDetail',
    path: '/newDetail',
    component: newDetail,
    meta: {
        keepAlive: false
    }
},
{
    name: 'news',
    path: '/news',
    component: news,
    meta: {
        keepAlive: false
    }
},
{
    name: 'newsDetail',
    path: '/newsDetail',
    component: newsDetail,
    meta: {
        keepAlive: false
    }
},
{
    name: 'video',
    path: '/video',
    component: video,
    meta: {
        keepAlive: false
    }
},
{
    name: 'login',
    path: '/login',
    component: login,
    meta: {
        keepAlive: false
    }
},
{
    name: 'brandDetail',
    path: '/brandDetail',
    component: brandDetail,
    meta: {
        keepAlive: false
    }
},
{
    name: 'auditaccount',
    path: '/auditaccount',
    component: auditaccount,
    meta: {
        keepAlive: false
    }
},
{
    name: 'quality',
    path: '/quality',
    component: quality,
    meta: {
        keepAlive: false
    }
},
{
    name: 'employ',
    path: '/employ',
    component: employ,
    meta: {
        keepAlive: false
    }
},
{
    name: 'marketguidance',
    path: '/marketguidance',
    component: marketguidance,
    meta: {
        keepAlive: false
    }
},
{
    name: 'sellersupport',
    path: '/sellersupport',
    component: sellersupport,
    meta: {
        keepAlive: false
    }
},
{
    name: 'officelocation',
    path: '/officelocation',
    component: officelocation,
    meta: {
        keepAlive: false
    }
},
{
    name: 'favorite',
    path: '/favorite',
    component: favorite,
    meta: {
        keepAlive: false
    }
},
{
    name: 'oem',
    path: '/oem',
    component: oem,
    meta: {
        keepAlive: false
    }
},
{
    name: 'support',
    path: '/support',
    component: support,
    meta: {
        keepAlive: false
    }
},
{
    name: 'amazon',
    path: '/amazon',
    component: amazon,
    meta: {
        keepAlive: false
    }
},
{
    name: 'services',
    path: '/services',
    component: services,
    meta: {
        keepAlive: false
    }
},
{
    name: 'partnership',
    path: '/partnership',
    component: partnership,
    meta: {
        keepAlive: false
    }
},
{
    name: 'inquiry',
    path: '/inquiry',
    component: inquiry,
    meta: {
        keepAlive: false
    }
}]

const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

router.beforeEach((to, from, next) => {
    let obj = store.state.user
    if (templates() === 'themeTemplateFour') {
        if (obj.userInfo.id && obj.token) {
            if (to.name === 'login') {
                next('/home')
            } else {
                next()
            }
        } else {
            if (to.name !== 'login') {
                next('/')
            } else {
                next()
            }
        }
    } else {
        if (to.name === 'login') {
            next('/home')
        } else {
            next()
        }
    }
})

export default router