<!--
 * @Description: 审核页面
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-09-11 12:22:39
-->
<template>
  <div>
    <component :is="componentName"></component>
  </div>
</template>
<script>
import mixins from '@/utils/mixins'
export default {
  name: 'homePage',
  mixins: [mixins],

  data() {
    return {
      componentName: ''
    }
  },
  created() {
    this.initDom()
  },
  methods: {
    initDom() {
      switch (this.$templates()) {
        case 'themeTemplateSix':
          this.componentName = 'pageAuditSix'
          break
        case 'themeTemplateSeven':
          this.componentName = 'pageAuditSeven'
          break
        default:
          this.componentName = ''
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>