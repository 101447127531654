/*
 * @Description: 
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-09-19 09:44:41
 */
import VueI18n from 'vue-i18n'
import Vue from 'vue'

//引入element的语言包
import enLocale from 'element-ui/lib/locale/lang/en' //英文
import zhLocale from 'element-ui/lib/locale/lang/zh-CN' //中文
import ruLocale from 'element-ui/lib/locale/lang/ru-RU' //俄语

// 引入需要语言包也可是js文件，export default抛出语言包的对象
import en from './lang/saas-en.js'
import zh from './lang/saas-zh-CN.js'
import ru from './lang/saas-ru-RU.js'

// 欧尚宝语言
import enYY from './lang/oushangbao/saas-en.js'
import zhYY from './lang/oushangbao/saas-zh-CN.js'
import ruYY from './lang/oushangbao/saas-es.js'

Vue.use(VueI18n)
let lang = localStorage.getItem('i18n') || 'en' || 'es'

export default new VueI18n({
    locale: lang,
    messages: {
        en: Object.assign(en, enYY, enLocale),
        zh: Object.assign(zh, zhYY, zhLocale),
        ru: Object.assign(ru, ruYY, ruLocale),
    }
})