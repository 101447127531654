<!--
 * @Description: 登录页
 * @Author: zml
 * @Date: 2023-04-13 18:05:51
 * @LastEditors: zml
 * @LastEditTime: 2023-04-14 16:29:42
-->

<template>
  <div>
    <component :is="componentName"></component>
  </div>
</template>
<script>
import mixins from '@/utils/mixins'
export default {
  name: 'videoPage',
  mixins: [mixins],

  data() {
    return {
      componentName: ''
    }
  },
  created() {
    this.initDom()
  },
  methods: {
    initDom() {
      switch (this.$templates()) {
        case 'themeTemplateFour':
          this.componentName = 'pageLoginFour'
          break
        default:
          this.componentName = ''
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>