import { render, staticRenderFns } from "./productlist.vue?vue&type=template&id=147ca265&scoped=true&"
import script from "./productlist.vue?vue&type=script&lang=js&"
export * from "./productlist.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "147ca265",
  null
  
)

export default component.exports