/*
* @Description: 赢鱼英文
* @Author: zml
* @Date: 2023-03-23 14:52:59
 * @LastEditors: zml
 * @LastEditTime: 2023-09-28 17:28:57
*/
export default {
    "OSB.login": "Login",
    "OSB.loginMsg": "Access to your account & order",
    "OSB.login.EmailRoMobile": "Email / Mobile",
    "OSB.login.password": "Password",
    "OSB.login.Record": "Remember me",
    "OSB.Register.title": "Log in",
    "OSB.Register.userAccount": "User Account",
    "OSB.Register.Mobile": "Mobile",
    "OSB.Register.Email": "Email",
    "OSB.Register.Company": "Company",
    "OSB.Reset.title": "Log in",
    "OSB.register": "Register",
    "OSB.logout": "Logout",
    "OSB.MyOrder": "My Order",
    "OSB.logoBox.placeholder": "Please enter the product keyword",
    "OSB.logoBox.search": "Search",
    "OSB.logoBox.AdvancedSearch": "Advanced Search",
    "OSB.nav.Home": "Home",
    "OSB.nav.Products": "PRODUCTS",
    "OSB.nav.Shop": "Shop",
    "OSB.nav.Service": "Service",
    "OSB.nav.Contacts": "Contact",
    "OSB.nav.Order": "Order",
    "OSB.nav.OurServices": "Our Services",
    "OSB.nav.About": "About Us",
    "OSB.nav.OurMissions": "Our Missions",
    "OSB.nav.Categories": "Categories",
    "OSB.nav.QualityControl": "Quality Control",
    "OSB.nav.SmallBusinessSupport": "S&M Business Support",
    "OSB.nav.AmazonSellersSupport": "Amazon Sellers Support",
    "OSB.nav.BecomeOurPartner": "Become Our Partner",
    "OSB.nav.SubmitYourInquiry": "Submit Your Inquiry",
    "OSB.nav.contact": "Contact Us",
    "OSB.nav.news": "NEWS",
    "OSB.nav.cart": "CART",
    "OSB.ViewPrice": "View Price",
    "OSB.SameFactory": "Same Factory",
    "OSB.newPro": "NEW ARRIVIALS",
    "OSB.AnotherBatch": "Another Batch",
    "OSB.ViewMore": "View More",
    "OSB.hotPro": "HOT ITEMS",
    "OSB.hotVidio": "HOT ITEM VODEO",
    "OSB.proUnBoxVidio": "PRODUCT UNBOXING VIDEO",
    "OSB.Search": "Search",
    "OSB.Reset": "Reset",
    "OSB.Description": "Description (Any Part Of Product Name)",
    "OSB.ItemNO": "Item NO.",
    "OSB.MTCode": "MT Code",
    "OSB.FTYCode": "FTY Code",
    "OSB.Kind": "Kind",
    "OSB.Size": "Size",
    "OSB.Price": "Price",
    "OSB.Date": "Date",
    "OSB.Sort": "Sort",
    "OSB.DateDesc": "DATE DESC",
    "OSB.DateAsc": "DATE ASC",
    "OSB.PriceDesc": "PRICE DESC",
    "OSB.PriceAsc": "PRICE ASC",
    "OSB.NO.Desc": "NO. DESC",
    "OSB.NO.Asc": "NO. ASC",
    "OSB.PleaseEnterTheProductNameKeyword": "Please enter the product name keyword",
    "OSB.PleaseSelect": "Please select",
    "OSB.PleaseLength": "Length",
    "OSB.PleaseWidth": "Width",
    "OSB.PleaseHeight": "Height",
    "OSB.PleaseMinPrice": "Min price",
    "OSB.PleaseMaxPrice": "Max price",
    "OSB.PleaseStartDate": "Start date",
    "OSB.PleaseEndDate": "End date",
    "OSB.UserAccount": "User Account",
    "OSB.password": "Password",
    "OSB.GROUPSEARCH": "GROUPSEARCH",
    "OSB.Video_playback": "Video playing",
    "OSB.onUserAccount": "Don’t have an account",
    "OSB.SignUp": "Sign up",
    "OSB.ForgetPassword": "Forget password?",
    "OSB.resetPasswords": "Reset password",
    "OSB.emailAddress": "Email address",
    "OSB.verifyCode": "Verify code",
    "OSB.getVerifyCode": "Get code",
    "OSB.newPassword": "New password",
    "OSB.confirmPassword": "Confirm password",
    "OSB.Submit": "Submit",
    "OSB.SignUpAccount": "Sign up",
    "OSB.LoginOnly": "Login only",
    "OSB.Name": "Name",
    "OSB.NameMsg": "Please enter your name, 2 to 30 characters",
    "OSB.passwordMsg": "At least 6 characters",
    "OSB.requiredFields": "*Required fields",
    "OSB.Email": "Email",
    "OSB.EmailMsg": "Please enter email. Used for login",
    "OSB.gender": "Gender",
    "OSB.man": "Man",
    "OSB.woman": "Woman",
    "OSB.customerSource": "Customer source",
    "OSB.salesman": "Contact Salesman",
    "OSB.Country": "Country",
    "OSB.companyName": "Company name",
    "OSB.duty": "Job Titel",
    "OSB.tel": "Tel",
    "OSB.mobile": "Mobile",
    "OSB.fax": "Fax",
    "OSB.Yahoo": "Yahoo",
    "OSB.website": "Website",
    "OSB.address": "Address",
    "OSB.postalCode": "Postal code",
    "OSB.ExistingAccount": "Existing Account",
    "OSB.DirectLogin": "Log in directly!",
    "OSB.productPrice": "Price",
    "OSB.ProductNumber": "Item NO.",
    "OSB.Packing": "Packing",
    "OSB.OuterBoxCBM": "CBM",
    "OSB.OuterBoxCUFT": "CUFT",
    "OSB.Innerboxes": "Inner Boxes",
    "OSB.OuterQTY": "Outer QTY",
    "OSB.GWAndNW": "G.W/N.W",
    "OSB.productSize": "Product Size",
    "OSB.packageSize": "Package Size",
    "OSB.cartonSize": "Carton Size",
    "OSB.addCart": "Add To Cart",
    "OSB.delCart": "Cancel Purchase",
    "OSB.product": "Product",
    "OSB.NewsUpdates": "News Updates",
    "OSB.ReleaseDate": "Release Date",
    "OSB.MyCart": "My Cart",
    "OSB.ID": "ID",
    "OSB.picture": "Picture",
    "OSB.ArticleNo": "Item No.",
    "OSB.Description": "Description",
    "OSB.CartOuterQTY": "Outer QTY",
    "OSB.CBM": "CBM",
    "OSB.CTNS": "CTNS",
    "OSB.totalQTY": "Total QTY",
    "OSB.TotalVolume": "Total CBM",
    "OSB.total": "Total",
    "OSB.delete": "Delete",
    "OSB.checkAll": "Check All",
    "OSB.clear": "Clear",
    "OSB.totalItems": "Total Items",
    "OSB.totalCTNS": "Total CTNS",
    "OSB.noData": "No Data",
    "OSB.totalPrice": "Total Price",
    "OSB.systemTip": "Tip",
    "OSB.confirm": "Confirm",
    "OSB.cancel": "Cancel",
    "OSB.deleteData": "Do you want to delete the data?",
    "OSB.clearCartData": "Do you want to empty your shopping cart?",
    "OSB.submitTip": "Confirm to submit the order?",
    "OSB.MyOrder": "My Order",
    "OSB.PleaseOrderNum": "Please enter the order number",
    "OSB.number": "Number",
    "OSB.viewDetails": "View Details",
    "OSB.orderTime": "Order Time",
    "OSB.linkman": "Linkman",
    "OSB.sendDate": "Delivery Date",
    "OSB.expressNumber": "Tracking Number",
    "OSB.orderStatus": "Order Status",
    "OSB.unshipped": "Unshipped",
    "OSB.shipped": "Shipped",
    "OSB.SiteNavigation": "Site Navigation",
    "OSB.ContactUs": "Contact us",
    "OSB.FollowUs": "Follow us",
    "OSB.Landline": "Landline",
    "OSB.Email": "E-mail",
    "OSB.Phone": "Phone",
    "OSB.WeChat": "WeChat",
    "OSB.Technic": "Technic Sustain",
    "OSB.Software": "HongSheng Soft",
    "OSB.detailBtn": "Product details",
    "OSB.noDetailBtn": "No product details",
    "OSB.classify": "CATALOG",
    "OSB.exclusive": "EXCLUSIVE RECOMMENDATIONS",
    "OSB.loading": "Loading...",
    "OSB.updateTime": "UpdateTime",
    "OSB.SearchMsg": "The above search criteria can be any one or more comprehensive searches",
    "OSB.MessageTitle": "Submit your requirements online",
    "OSB.MessageMsg": "We will reply to your email within 24 hours, excluding holidays",
    "OSB.MessageName": "YOUR NAME",
    "OSB.MessageTel": "YOUR PHONE",
    "OSB.MessageEmail": "YOUR EMAIL",
    "OSB.MessageNeeds": "YOUR NEEDS",
    "OSB.SendInquiry": "Send Inquiry",
    "OSB.EnquiryList": "ENQUIRY",
    "OSB.enquiry": "Enquiry",
    "OSB.MyEnquiryList": "My Inquiry Form",
    "OSB.Remark": "Remark",
    "OSB.Enquirys": "Leave your email and we'll get back to you",
    "OSB.proClass": "Product Category",


    "OSB.topWelcome": "Welcome to the homepage of ING Toys' official website",
    "OSB.searchPro": "Search...",
    "OSB.NewArrivals": "New Arrivals",
    "OSB.BestSellers": "Best Sellers",
    "OSB.SpecialOffers": "Special Offers",
    "OSB.OurServices": "Our Services",
    "OSB.OurServicesMsg": "ING Toys aims to become the most powerful toy supplier globally. Our core strengths are demonstrated in the following areas: an extensive selection of 1,000,000+ diverse products, efficient service speed to quickly meet market demands, ensuring that customers do not miss any business opportunities.",
    "OSB.oemMsg": "Unlocking Your Brand's Potential with Custom ODM/OEM Services",
    "OSB.oemMsg1": "Unlocking Opportunities with Low MOQ (Minimum Order Quantity) Services",
    "OSB.oemMsg2": "Empowering Your Amazon Business with Supply Chain Excellence",
    "OSB.SupportTitle": "Small Business Support",
    "OSB.AmazonTitle": "Amazon Seller Support",
    "OSB.ourMissionTitle": "OUR MISSIONS",
    "OSB.ourMissionMsg": "We give our partners maximum support in optimising processes within the distribution chain in order to help them achieve a competitive edge on the market.",
    "OSB.EASY_SOURCING": "Easy Search",
    "OSB.EASY_Msg1": "An easy way to post your sourcing,requests and quotes.",
    "OSB.EASY_Msg2": "Together, let's create a world where laughter, learning, and play go hand in hand.",
    "OSB.proName": "Product Name",
    "OSB.searchPacking": "Packing",
    "OSB.QualityControlMsg": "Quality is our promise. Each toy in our inventory is crafted to meet the highest standards of safety and durability. Rest easy knowing that you're delivering smiles that will last.",
    "OSB.amazon1.title": "Multiple Private Label & Packaging Ideas",
    "OSB.amazon1.msg1": "Low MOQ, tight budgets, and the difficulty of finding well-matched suppliers on Alibaba canmake it hard to develop your brand products. We're here to help you",
    "OSB.amazon1.msg2": "Alter Materials, Sizes, And Colors Based On Existing Products",
    "OSB.amazon1.msg3": "Develop Brand New Products According To Your 3d Designs",
    "OSB.amazon1.msg4": "Customize Packaging To Stand Out From Your Competitors",
    "OSB.amazon1.msg5": "Kit And Bundle Products To Generate More Sales",
    "OSB.amazon2.title": "1-by-1 Quality Inspection",
    "OSB.amazon2.titleMsg1": "Empowering Your Amazon Business with Supply Chain Excellence",
    "OSB.amazon2.titleMsg2": "Are you an Amazon seller seeking reliable supply chain solutions to fuel your e-commerce success? Look no further! We specialize in providing top-notch Amazon Supply Chain Services designed to streamline your operations and ensure a seamless flow of inventory",
    "OSB.amazon2.titleMsg3": "Why Choose Our Amazon Supply Chain Services?",


    "OSB.amazon2.msg1": "As an Amazon seller, getting bad reviews because of product quality issues can be fatal. Inspection companies and suppliers can check 20–30% of your boxes but they’ll only tell you whether the result is a fail or pass.",
    "OSB.amazon2.msg2": "We can inspect your items one by one for just a small labor fee. We’ll identify every defective item and request the Chinese factory to fix it.",
    "OSB.amazon3.title": "Low-cost Amazon Fba Labeling",
    "OSB.amazon3.msg1": "The Amazon FBA label service fee is $0.55 per unit and that of the 3rd party is $0.2–$0.3 per unit. We can add labels for $5 per hour,which is much more cost-effective. The labels include:",
    "OSB.amazon3.msg2": "Fnsku Label",
    "OSB.amazon3.msg3": "Shipping Label",
    "OSB.amazon3.msg4": "Warning Sticker",
    "OSB.amazon3.msg5": "Other Labels Amazon Requires",
    "OSB.amazon4.title": "Free Storage In China For 1-2 Months",
    "OSB.amazon4.msg1": "Amazon storage is expensive. And during peak season, sellers have limited storage capacity in Amazon arehouses. We offer free  storage for 1–2 months in China for your convenience.",
    "OSB.amazon4.msg2": "To reduce Amazon storage fees, we can store some of your  products in our warehouse and send them to the Amazon FBA  warehouse in batches at different times when you need replenishment.",
    "OSB.amazon.contact": "Partner with us today and let our Amazon Supply Chain Services become your trusted partner in success. We're dedicated to helping you streamline your operations, reduce costs, and deliver exceptional service to your Amazon customers. Contact us now to embark on a journey of supply chain excellence!”",

    "OSB.partnership1.title": "Our Advantages",
    "OSB.partnership1.msg1": "We understand the dynamics of your business. With competitive pricing and flexible ordering options, we're your strategic ally for success. Whether you're a retailer, educator, or event planner, we've got you covered.",
    "OSB.partnership1.msg2": "Endless Variety",
    "OSB.partnership1.msg3": "Quality Beyond Measure",
    "OSB.partnership1.msg4": "Your One-Stop Toy Hub",
    "OSB.partnership1.msg5": "Spreading Smiles",
    "OSB.partnership1.msg6": "Your Partner in Success",
    "OSB.partnership1.msg7": "Partner B2B Web Service",
    "OSB.partnership2.title": "Partner B2B Web Service",
    "OSB.partnership2.msg1": "We're your ultimate partner in play. From classic wonders to cutting-edge novelties, we've carefully curated an extensive collection that caters to all preferences.",
    "OSB.partnership2.msg2": "Step into a world where every imagination is catered to. From educational wonders that spark curiosity to playful companions that offer comfort, our diverse collection promises something for every child's dream.",
    "OSB.partnership2.msg3": "Web Shop",
    "OSB.partnership3.title": "Multiple ways to contact us",
    "OSB.partnership3.msg1": "Join us in the pursuit of making childhood memories brighter and more memorable. Let's collaborate and create a world of happiness.",
    "OSB.partnership3.msg2": "Phone",
    "OSB.partnership3.msg3": "Whatspp/Wechat",
    "OSB.partnership3.msg4": "Email",
    "OSB.inquiry.title": "Haven’t found the products you are needed in our website?",
    "OSB.inquiry.msg1": "Let's build a thriving partnership where your success fuels ours. Connect with us today and embark on a journey of mutual growth, boundless fun, and extraordinary potential.",
    "OSB.inquiry.form.title": "Select Your Sourcing Request",
    "OSB.inquiry.FirstName": "First Name",
    "OSB.inquiry.lastName": "Last Name",
    "OSB.inquiry.Email": "Email",
    "OSB.inquiry.Phone": "Phone(eg.+12345678)",
    "OSB.inquiry.Address": "Address for shipping samples",
    "OSB.inquiry.Company": "Company",
    "OSB.inquiry.Postal/ZipCode": "Postal/Zip code",
    "OSB.inquiry.Country": "Country",
    "OSB.inquiry.BusinessType": "Business Type",
    "OSB.inquiry.Remark": "Tell us what kind of products do you need",
    "OSB.inquiry.Upload": "Upload attachments",
    "OSB.inquiry.Submit": "Submit",

    "OSB.oem.titileMsg": "Unlocking Your Brand's Potential with Custom ODM/OEM Services",
    "OSB.oem.welcome": "Are you looking to elevate your brand and stand out in the market? Look no further! We specialize in offering top-tier ODM (Original Design Manufacturer) and OEM (Original Equipment Manufacturer) services that can bring your unique product ideas to life.",
    "OSB.oem1.title1": "ODM Solution",
    "OSB.oem1.content1": "With our ODM services, we provide innovative design solutions tailored to your specific needs, helping you create products that truly reflect your brand's identity. Our team of experts will work closely with you to develop, design, and manufacture products that exceed your expectations.",
    "OSB.oem1.title2": "OEM Solution",
    "OSB.oem1.content2": "If you already have a product concept in mind, our OEM services allow you to leverage our manufacturing capabilities to produce high-quality, custom-branded products. We understand the importance of maintaining your brand integrity, and our OEM services ensure that your products meet the highest standards of quality and craftsmanship.",
    "OSB.oem2.title": "Why Choose Our ODM/OEM Services?",
    "OSB.oem2.title1": "Customization",
    "OSB.oem2.msg1": "We believe in turning your vision into reality. Our ODM/OEM services are all about customization, ensuring that your products are unique and tailored to your brand.",
    "OSB.oem2.title2": "Quality Assurance",
    "OSB.oem2.msg2": "We are committed to delivering products of the highest quality. Our rigorous quality control processes guarantee that every product meets the industry's standards.",
    "OSB.oem2.title3": "Efficiency",
    "OSB.oem2.msg3": "Time is money, and we understand the importance of timely delivery. Our streamlined processes ensure that your products are manufactured and delivered efficiently.",
    "OSB.oem2.title4": "Cost-Effective Solutions",
    "OSB.oem2.msg4": "We offer competitive pricing without compromising on quality. Our ODM/OEM services are designed to maximize your ROI",
    "OSB.oem2.title5": "Global Reach",
    "OSB.oem2.msg5": "We have a global presence, allowing us to cater to clients worldwide. No matter where you are, we can provide the services you need.",
    "OSB.oem.contact": "Partner with us today and take your brand to new heights with our ODM/OEM services. Let's turn your ideas into market-leading products that captivate your audience and drive success. Contact us now to explore the endless possibilities!",

    "OSB.support.titileMsg": "Unlocking Opportunities with Low MOQ (Minimum Order Quantity) Services",
    "OSB.support.welcome1": "Are you a startup, small business, or entrepreneur seeking to bring your innovative product ideas to life without the burden of high order quantities?",
    "OSB.support.welcome2": "Look no further! We specialize in providing Low MOQ Services that empower you to turn your concepts into reality with ease and affordability.",
    "OSB.support1.title": "Why Choose Our Low MOQ Services?",
    "OSB.support1.title1": "Accessibility",
    "OSB.support1.msg1": "Our Low MOQ services make product development accessible to businesses of all sizes. Whether you need a small batch for testing the market or have limited storage space, we've got you covered.",
    "OSB.support1.title2": "Cost-Effective",
    "OSB.support1.msg2": "Say goodbye to excessive inventory costs. Our Low MOQ services allow you to minimize upfront expenses and manage your budget efficiently.",
    "OSB.support1.title3": "Product Diversity",
    "OSB.support1.msg3": "Explore a wide range of products without the need for large orders. We're here to help you diversify your offerings.",
    "OSB.support1.title4": "Market Agility",
    "OSB.support1.msg4": "Stay agile and responsive to market trends. With Low MOQ services, you can quickly adapt to changing consumer preferences and launch new products faster.",
    "OSB.support1.title5": "Quality Assurance",
    "OSB.support1.msg5": "We maintain the same high standards for quality and craftsmanship, regardless of order size. Your products will meet industry standards and exceed customer expectations.",
    "OSB.support1.title6": "Flexible Scaling",
    "OSB.support1.msg6": "As your business grows, our services can scale with you. Transition seamlessly from lo",
    "OSB.support.contact": "Don't let high MOQ slimit your potential. Partner with us today, and together, we'll bring your unique product concepts to life with our Low MOQ Services. Experience the flexibility, affordability, and quality that sets us apart. Contact us now to embark on your journey of innovation and growth!",
    "OSB.myfavorite": 'My Favorite',
    "OSB.video": 'Video',
    "OSB.blog": "Blog",
    "OSB.title": "One of the largest board game & DIY toy factories in Shantou",
    "OSB.text1": "Over 20 years of professional experience.",
    "OSB.text2": "Offer a range of 300+ board games and 200+ DIY toys.",
    "OSB.text3": "OEM services have benefitted more than 3000 clients.",
    "OSB.learnmoreabout": "learn more about",
    "OSB.exportingtoys": "a trading company with 7 years of experience in exporting toys",
    "OSB.wsmxzwm": "WHY CHOOSE US",
    "OSB.ToysManufacturer": "Import Toys from Chinese Toys Manufacturer",
    "OSB.OEM": "OEM",
    "OSB.OEMcontent": "Support in customizing package, logos, developing molds, and developing your own exclusive brand.",
    "OSB.guide": "Shantou toy market guide",
    "OSB.guidecontent": "10000 + ㎡ showroom, 6 millions + toys.The world's largest toy manufacturing base.",
    "OSB.office": "Your purchase office in China",
    "OSB.officecontent": "Import more than toys? Our professional purchase department pals can help.",
    "OSB.business_support": "Amazon seller support",
    "OSB.business_supportcontent": "Small MOQ, Inspection & QC(100% guaratee), Free storage",
    "OSB.sellersupport": "Trend Analysis Report",
    "OSB.sellersupportcontnet": "Update the latest product trends and reports on a monthly basis. Helping you stay at the forefront",
    "OSB.inquiry": "Submit your inquiry",
    "OSB.inquirycontent": "24 hours on line, start growing with Osanbo today. Take action now and contact us.",
    "OSB.htzz": "Haitian zhizao",
    "OSB.serviceexperience": '"Submit your inquiry. According to your doubts, we will provide you with one-to-one professional consultants to answer your doubts and explore your real needs according to your inquiries. Let you have a better service experience.',
    "OSB.amplerequest": "Simply enter the product number, product name,classification, price range, and packaging method, find the products you need within 1 second.",
    "OSB.ReadyChina": "Ready to Import from China?",
    "OSB.Wecanhelpyou": "Start growing with OSANBO TOYS today. We can help you wholesale or customize products at the most competitive prices.",
    "OSB.competitiveprices": "wholesale or customize products at the most competitive prices.",
    "OSB.ClientsNow": "Join Our 5000+ Clients Now",
    "OSB.Contact": "Contact",
    "OSB.followus": "follow us",
    "OSB.keywords": "Please enter keywords",
    "OSB.Hearbest": "5000+ Clients Trust Osanbo",
    "OSB.evaluations": "We have clients with various business all over the world. Listen to what they’ve said about us.",
    "OSB.showroom": "Click to enter our online showroom , All you need are here",
    "OSB.toys": "10k + showroom, 6 millions + toys, 4k+ Clients choose toys here",
    "OSB.toyshere": "4k+ Clients choose toys here",
    "OSB.seeshowroom": "View online showroom",
    "OSB.PleaseLogin": "Please Login",
    "OSB.last": "Last",
    "OSB.next": "Next",
    "OSB.addtofavorite": "Add to favorite",
    "OSB.Business": "Do You Want To Boost Your Business?",
    "OSB.marketguide": "Shantou toy market guide",
    "OSB.purchaseoffice": "Your purchase office in China",
    // "OSB.sellersupport": "Amazon seller support",
    "OSB.Submityourinquiry": "Submit your inquiry",
    "OSB.tradingbusiness1": "In 2015, Started toy trading business.",
    "OSB.tradingbusiness2": "Our procurement department work as purchasing agent in China for clients.",
    "OSB.tradingbusiness3": "(Expcecially in Shantou, Guangzhou, Yiwu & Ningbo)",
    "OSB.tradingbusiness4": "To follow up all orders to save time and cost.",
    "OSB.followup": "To follow up all orders to save time and cost.",
    "OSB.Freesoucing1": "Free Sourcing",
    "OSB.Freesoucingtxt1": "Sourcing products to catch your market demand, find reliable factories before you come to China. So you can make your gudget at amoke time and save time  in China.",
    "OSB.Freesoucing2": "Consolidation from multiple factories all over China",
    "OSB.Freesoucingtxt2": "Assist in collecting goods from all over China and concentrating them in the same place for container loading. Save time in contacting suppliers and improve efficiency.",
    "OSB.Freesoucing3": "Rearch & Development",
    "OSB.Freesoucingtxt3": "If you cannot find the prododucts you want, we will assist to develop your own idea. Make it from define to actual.Artwork design/make packing according to customer's demand/barcode reading test/ sampling and product testing(TUV, SGS, BV etc.)",
    "OSB.Freesoucing4": "Travel arrangement & translation",
    "OSB.Freesoucingtxt4": "Invitaion letter/ hotel best discount and VIP service/ airport pick up. Make a plan according to you schedule, help you save cost and time.",
    "OSB.Freesoucing5": "Inspection & QC(100% guaratee)",
    "OSB.Freesoucingtxt5": "Professional QC team will conduct strict inpection for you, 100 % eunsure all products are the same as your order.",
    "OSB.Freesoucing6": "Warehousing & repacking",
    "OSB.Freesoucingtxt6": "Free warehousing offered, if you want to change  packinage, we will do it as per your instruction.",
    "OSB.Freesoucing7": "Logistic & documents",
    "OSB.Freesoucingtxt7": "We cooperate with most of the biggest shipping company and can ship your cargo to anywhere of the world. Relevant documents will be provided, such as PL, BL,CI etc.",
    "OSB.getstarted": "Get Started",
    "OSB.inquiry.title": "Submit Your Sourcing Request",
    "OSB.inquiry.msg": "Fill out this form with your detailed needs and our customer support team will contact you shortly. We will assign a professional staff to follow up on your project and provide personalized assistance.To get the fastest response, submit your inquiries using the form. If you encounter any issues with submission, you can also email us directly at sales@osanbotoys.com.",
    "OSB.server.showroom": "10000 + ㎡ showroom, 6 millions + toys",
    "OSB.server.showroomTitle": "Check below the video to understand how it works",
    "OSB.server.showroomtext1title": "Full-process shopping guide, business negotiation office space, ",
    "OSB.server.showroomtext1": "communication and docking with factories--Charge0! ",
    "OSB.server.showroomtext1box": "Special car transfer, workandcatering, etc. --Charge0 !",
    "OSB.server.showroomtext2title": "More than 25000 booths of various types: ",
    "OSB.server.showroomtext2": "Educational Toys, Board Games, Electric Toys, Barbie Dolls, Kitchen Playsets, Inflatable Toys, Building Blocks, RC toys etc.",
    "OSB.server.showroomtext3": "Meet 25000 + factories directly.",
    "OSB.server.showroomtext4title": "Efficient and fast selection of the latest best-selling toys. ",
    "OSB.server.showroomtext4": "Negotiate face-to-face with the factory to obtain the best price. ",
    "OSB.server.showroomtext4box": "Year-round one-stop toy procurement.",
    "OSB.server.suppliers1": "Struggling to develop your brand products due to low MOQ, tight budgets, and difficulty finding well-matched suppliers?",
    "OSB.server.supplierstext1": "Look no further! We are here to help. We can alter materials, sizes, and colors based on existing products, develop brand new products according to your 3D designs, customize packaging to stand out from competitors.",
    "OSB.server.suppliers2": "Tired of receiving bad reviews on Amazon due to product quality issues?",
    "OSB.server.supplierstext2": "We do 1-by-1 Quality Inspection, offers a solution. We meticulously inspect each item, identifying every defective one.Communicate with the Chinese factory to have them fixed. ",
    "OSB.server.suppliers3": "Enjoy Our Labeling Service.",
    "OSB.server.supplierstext3": "Includes all necessary labels required by Amazon, such as the FNSKU label, shipping label, warning sticker, and any other specific labels requested by the platform.",
    "OSB.server.suppliers4": "Free Storage In China For 2 Months.",
    "OSB.server.supplierstext4": "Amazon storage is expensive. And during peak season, sellers have limited storage capacity in Amazon arehouses. We offer free  storage for 2 months in China for your convenience.",
    "OSB.server.oem1": "Cannot find the prododucts you want.",
    "OSB.server.oem2": "we will assist you to develop your own idea.",
    "OSB.server.oem3": "Make it from define to actual.",
    "OSB.server.development1": "Packing development",
    "OSB.server.development2": "Mold development",
    "OSB.server.mouldbrand1": "Client confirm which product to be customise?",
    "OSB.server.mouldbrand2": "Osanbo provide blank diecut artwork for client to deisgn their packing.",
    "OSB.server.mouldbrand3": "Once receiving bespoke artwork & sample fee, Osanbo will make digital printed samples and send for confirmation.",
    "OSB.server.mouldbrand4": "After receving and checking sample, if need make any adjustment, Osanbo will remake sample & resend to confirm.",
    "OSB.server.mouldbrand5": "Client confirm final sample, price, order quantity.",
    "OSB.server.mouldbrand6": "Sign agreement and contact",
    "OSB.server.mouldtab1": "6 Steps to bespoke your own packing folling below process",
    "OSB.server.molddevelopment1": "Client send us cront view, side view, top view of design. ",
    "OSB.server.molddevelopmenttext1": "Or description of shape, size.Or provide Osanbo a reference sample.Provide packing artwork, label artwork, packing specification of shipping mark(show QTY per inner and outer carton).Inform estimated order quantity.",
    "OSB.server.molddevelopment2": "Osanbo calculate product cost according to product artwork.",
    "OSB.server.molddevelopmenttext2": "Inform 3D design, 3D sample cost & time,sample fee & time.prodouct unit price & estimated lead time.",
    "OSB.server.molddevelopment3": "Reach a price agreement with client and receive sample fee.",
    "OSB.server.molddevelopmenttext3": "Osanbo will start to make 3D design and send 3D artwork for confirmation.",
    "OSB.server.molddevelopment4": "After artwork approved, Osanbo make 3D printed sample or mud mock up, digital printed box & label Well pack and send completed samples by DHL/UPS/FEDEX  for confimation.",
    "OSB.server.molddevelopment5": "After first sample apprved, client arrange test by TUV/SGS/BV testing. ",
    "OSB.server.molddevelopment6": "Pass testing, sign agreement and contract.",
    "OSB.server.officehelp": "What Can We Help？",
    "OSB.server.officebox1": "Sourcing products to catch your market demand, find reliable factories.",
    "OSB.server.officebox2": "Consolidation from multiple factories all over China",
    "OSB.server.officebox3": "Assist to develop your own idea. Make it from define to actual.",
    "OSB.server.officebox4": "Invitaion letter/ hotel best discount and VIP service/ airport pick up. Make a plan according to schedule.",
    "OSB.server.officebox5": "Inspection & QC(100% guaratee)",
    "OSB.server.officebox6": "We do more than you think...",
    "OSB.server.purchasetext1": "Your purchase office in China",
    "OSB.server.purchasetext2": "So convenient and happy！Like working in your own company.  Your one-stop purchase office in China. ",
    "OSB.server.howwework": "How We Work?",
    "OSB.server.wholesale": "China Wholesale Markets For All Products",
    "OSB.myfavoriteon": "My Favorite",
    "OSB.server.ourpricing": "Our Pricing & Plan",
    "OSB.innerbox": "Inner box",
    "OSB.outerbox": "Outer box",
    "OSB.aboutus": "About us"
}   
