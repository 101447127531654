<!--
 * @Description: 
 * @Author: zml
 * @Date: 2022-12-28 11:12:46
 * @LastEditors: zml
 * @LastEditTime: 2023-09-11 12:22:56
-->
<template>
  <div>
    <component :is="componentName"></component>
  </div>
</template>
<script>
import mixins from '@/utils/mixins'
export default {
  name: 'addressPage',
  mixins: [mixins],
  data() {
    return {
      componentName: ''
    }
  },
  created() {
    this.initDom()
  },
  methods: {
    initDom() {
      switch (this.$templates()) {
        case 'themeTemplateOne':
          this.componentName = 'pageAddressOne'
          break
        case 'themeTemplateTow':
          this.componentName = 'pageAddressTow'
          break
        case 'themeTemplateThree':
          this.componentName = 'pageAddressThree'
          break
        case 'themeTemplateFour':
          this.componentName = 'pageAddressFour'
          break
        case 'themeTemplateFive':
          this.componentName = 'pageAddressFive'
          break
        case 'themeTemplateSix':
          this.componentName = 'pageAddressSix'
          break
        case 'themeTemplateSeven':
          this.componentName = 'pageAddressSeven'
          break
        default:
          this.componentName = ''
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>