/*
 * @Description: 
 * @Author: pcc
 * @Date: 2023-02-13 09:02:49
 * @LastEditors: pcc
 * @LastEditTime: 2023-02-14 14:03:48
 */
import watermark from 'watermarkjs'
import store from '@/store/index'

const transformMark = async (imgUrl) => {
    if (imgUrl === null || imgUrl === '' || !store.getters['setting/get_setting'].isOpen) {
        return imgUrl
    }

    let options = {
        init: function (imgUrl) {
            imgUrl.crossOrigin = 'anonymous'
        }
    }

    return await watermark([imgUrl], options).blob(target => {
        let context = target.getContext('2d')
        let metrics = context.measureText(store.getters['setting/get_setting'].watermark)
        let x = (target.width / 2) - (metrics.width + 70)
        let y = (target.height / 2) + 100 * 2

        context.translate(x, y)
        context.globalAlpha = 0.5
        context.fillStyle = '#fff'
        context.font = '60px Josefin Slab'
        context.rotate(-45 * Math.PI / 180)
        context.fillText(store.getters['setting/get_setting'].watermark, -50, 0)
        return target
    }).then(res => {
        const objectURL = window.URL.createObjectURL(res)
        return objectURL
    })
}

export default transformMark