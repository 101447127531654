/*
 * @Description: 项目文件路径
 * @Author: zml
 * @Date: 2022-12-29 17:18:10
 * @LastEditors: zml
 * @LastEditTime: 2023-09-14 11:46:48
 */
export default {
  components: {
    /**
     * 模板6 欧尚宝
     * themeTemplateSix
     */
    contactDetailSix: () => import('@/components/themeTemplateSix/page/detail/contact.vue'),
    aboutDetailSix: () => import('@/components/themeTemplateSix/page/detail/about.vue'),
    pageCartSix: () => import('@/components/themeTemplateSix/page/cart/index'),
    pageHomeSix: () => import('@/components/themeTemplateSix/page/home'),
    pageOrderDetailSix: () => import('@/components/themeTemplateSix/page/order/detail'),
    pageOrderSix: () => import('@/components/themeTemplateSix/page/order/index'),
    pageProDetailSix: () => import('@/components/themeTemplateSix/page/product/proDetail.vue'),
    pageProductSix: () => import('@/components/themeTemplateSix/page/product/index.vue'),
    pageProductlistSix: () => import('@/components/themeTemplateSix/page/product/productlist.vue'),
    pageAddressSix: () => import('@/components/themeTemplateSix/page/address/index'),
    pageNewsSix: () => import('@/components/themeTemplateSix/page/news/index'),
    pageNewsDetailSix: () => import('@/components/themeTemplateSix/page/news/detail.vue'),
    pageVideoSix: () => import('@/components/themeTemplateSix/page/video/index'),
    pageAuditSix: () => import('@/components/themeTemplateSix/page/audit/index'),
    pageQualitySix: () => import('@/components/themeTemplateSix/page/quality/index'),
    pageOemSix: () => import('@/components/themeTemplateSix/page/oem/index'),
    pageSupportSix: () => import('@/components/themeTemplateSix/page/support/index'),
    pageAmazonSix: () => import('@/components/themeTemplateSix/page/amazon/index'),
    pageServicesSix: () => import('@/components/themeTemplateSix/page/services/index'),
    pagePartnershipSix: () => import('@/components/themeTemplateSix/page/partnership/index'),
    pageInquirySix: () => import('@/components/themeTemplateSix/page/inquiry/index'),
    pageemploySix: () => import('@/components/themeTemplateSix/page/employ/index'),
    pagemarketguidanceSix: () => import('@/components/themeTemplateSix/page/marketguidance/index'),
    pagesellersupportSix: () => import('@/components/themeTemplateSix/page/sellersupport/index'),
    pageofficelocationSix: () => import('@/components/themeTemplateSix/page/officelocation/index'),
    pagefavoriteSix: () => import('@/components/themeTemplateSix/page/favorite/index')
  }
}
