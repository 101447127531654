<!--
 * @Description: 订单详情
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-09-11 12:21:27
-->
<template>
  <div class="order-detail">
    <component :is="componentName"></component>
  </div>
</template>
<script>
import mixins from '@/utils/mixins'
export default {
  name: 'orderDetailPage',
  mixins: [mixins],
  data() {
    return {
      componentName: ''
    }
  },
  created() {
    this.initDom()
  },
  methods: {
    initDom() {
      switch (this.$templates()) {
        case 'themeTemplateOne':
          this.componentName = 'pageOrderDetailOne'
          break
        case 'themeTemplateTow':
          this.componentName = 'pageOrderDetailTow'
          break
        case 'themeTemplateThree':
          this.componentName = 'pageOrderDetailThree'
          break
        case 'themeTemplateFour':
          this.componentName = 'pageOrderDetailFour'
          break
        case 'themeTemplateFive':
          this.componentName = 'pageOrderDetailFive'
          break
        case 'themeTemplateSix':
          this.componentName = 'pageOrderDetailSix'
          break
        case 'themeTemplateSeven':
          this.componentName = 'pageOrderDetailSeven'
          break
        default:
          this.componentName = ''
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.order-detail {
  width: 100%;
  padding: 10px 0;
}
</style>