import request from '@/utils/request'

// IP访问统计
export function ipView(data) {
	return request({
		url: 'front/VisitLog/AddVisitLog',
		method: 'post',
		data
	})
}

// 新增首页浏览统计
export function insertHomeView(data) {
	return request({
		url: 'front/ProductStatisticalWeb/InsertHomeView',
		method: 'post',
		data,
	})
}

// 新增列表页浏览统计
export function insertListView(data) {
	return request({
		url: 'front/ProductStatisticalWeb/InsertListView',
		method: 'post',
		data,
	})
}

// 新增产品明细页浏览统计
export function insertProductView(data) {
	return request({
		url: 'front/ProductStatisticalWeb/InsertProductView',
		method: 'post',
		data,
	})
}

// 新增购物车浏览统计
export function insertCartView(data) {
	return request({
		url: 'front/ProductStatisticalWeb/InsertCartView',
		method: 'post',
		data,
	})
}

// 新增关键词搜索统计
export function insertSearch(data) {
	return request({
		url: 'front/ProductStatisticalWeb/InsertSearch',
		method: 'post',
		data,
	})
}

// 新增加购统计
export function insertAddCart(data) {
	return request({
		url: 'front/ProductStatisticalWeb/InsertAddCart',
		method: 'post',
		data,
	})
}
