<!--
 * @Description: 产品页
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-09-11 12:20:22
-->
<template>
    <div>
      <component :is="componentName"></component>
    </div>
  </template>
  <script>
  import mixins from '@/utils/mixins'
  export default {
    name: 'productPage',
    mixins: [mixins],
    data() {
      return {
        componentName: ''
      }
    },
    created() {
      this.initDom()
    },
    methods: {
      initDom() {
        switch (this.$templates()) {
          case 'themeTemplateSix':
            this.componentName = 'pageProductlistSix'
            break
        
        }
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  </style>