/*
* @Description: 赢鱼中文
* @Author: zml
* @Date: 2023-03-23 14:52:59
 * @LastEditors: zml
 * @LastEditTime: 2023-09-28 16:55:43
*/
export default {
    "OSB.login": "登录",
    "OSB.loginMsg": "访问您的帐户和订单",
    "OSB.login.EmailRoMobile": "邮箱 / 手机",
    "OSB.login.password": "密码",
    "OSB.login.Record": "记住我",
    "OSB.Register.title": "注册账号",
    "OSB.Register.userAccount": "用户名",
    "OSB.Register.Mobile": "手机号",
    "OSB.Register.Email": "电子邮箱",
    "OSB.Register.Company": "公司",
    "OSB.Reset.title": "重置密码",


    "OSB.register": "注册",
    "OSB.logout": "退出",
    "OSB.MyOrder": "我的订单",
    "OSB.logoBox.placeholder": "请输入产品关键字",
    "OSB.logoBox.search": "搜索",
    "OSB.logoBox.AdvancedSearch": "高级搜索",
    "OSB.nav.Home": "首页",
    "OSB.nav.Products": "产品",
    "OSB.nav.Shop": "商铺",
    "OSB.nav.Service": "服务",
    "OSB.nav.Contacts": "联系",
    "OSB.nav.Order": "订单",
    "OSB.nav.OurServices": "我们的服务",
    "OSB.nav.About": "关于",
    "OSB.nav.OurMissions": "我们的使命",
    "OSB.nav.Categories": "类别",
    "OSB.nav.QualityControl": "质量控制",
    "OSB.nav.SmallBusinessSupport": "S&M 商业支持",
    "OSB.nav.AmazonSellersSupport": "亚马逊支持",
    "OSB.nav.BecomeOurPartner": "成为我们的伙伴",
    "OSB.nav.SubmitYourInquiry": "提交您的需求",


    "OSB.nav.contact": "联系我们",
    "OSB.nav.news": "新闻",
    "OSB.nav.cart": "购物车",
    "OSB.ViewPrice": "查看价格",
    "OSB.SameFactory": "同一个工厂",
    "OSB.newPro": "最新产品",
    "OSB.AnotherBatch": "换一批",
    "OSB.ViewMore": "查看更多",
    "OSB.hotPro": "热销产品",
    "OSB.hotVidio": "热点视频",
    "OSB.proUnBoxVidio": "开箱视频",
    "OSB.Search": "搜索",
    "OSB.Reset": "重置",
    "OSB.Description": "产品名称 （ 产品名称的任何部分 ）",
    "OSB.ItemNO": "项目编号",
    "OSB.MTCode": "公司编号",
    "OSB.FTYCode": "出厂货号",
    "OSB.Kind": "种类",
    "OSB.Size": "产品规格",
    "OSB.Price": "价格",
    "OSB.Date": "日期",
    "OSB.Sort": "排序",
    "OSB.DateDesc": "日期降序",
    "OSB.DateAsc": "日期升序",
    "OSB.PriceDesc": "价格降序",
    "OSB.PriceAsc": "价格升序",
    "OSB.NO.Desc": "公司编号降序",
    "OSB.NO.Asc": "公司编号升序",
    "OSB.PleaseEnterTheProductNameKeyword": "请输入产品名称关键字",
    "OSB.PleaseSelect": "请选择",
    "OSB.PleaseLength": "长",
    "OSB.PleaseWidth": "宽",
    "OSB.PleaseHeight": "高",
    "OSB.PleaseMinPrice": "最小价格",
    "OSB.PleaseMaxPrice": "最大价格",
    "OSB.PleaseStartDate": "开始日期",
    "OSB.PleaseEndDate": "结束日期",
    "OSB.UserAccount": "用户名",
    "OSB.password": "密码",
    "OSB.GROUPSEARCH": "综合搜索",
    "OSB.Video_playback": "视频播放",
    "OSB.onUserAccount": "没有账号",
    "OSB.SignUp": "立即注册",
    "OSB.ForgetPassword": "忘记密码？",
    "OSB.resetPasswords": "重置密码",
    "OSB.emailAddress": "电子邮箱",
    "OSB.verifyCode": "验证码",
    "OSB.getVerifyCode": "获取验证码",
    "OSB.newPassword": "新密码",
    "OSB.confirmPassword": "确认密码",
    "OSB.Submit": "提交",
    "OSB.SignUpAccount": "注册账号",
    "OSB.LoginOnly": "仅用于登录",
    "OSB.Name": "姓名",
    "OSB.NameMsg": "请输入您的姓名，2~30个字符",
    "OSB.passwordMsg": "至少6个字符",
    "OSB.requiredFields": "*必填项",
    "OSB.Email": "邮箱",
    "OSB.EmailMsg": "用于登录，注册后我们将向此邮箱发送邮件以验证有效性",
    "OSB.gender": "性别",
    "OSB.man": "男",
    "OSB.woman": "女",
    "OSB.customerSource": "客户来源",
    "OSB.salesman": "销售员",
    "OSB.Country": "国家",
    "OSB.companyName": "公司名称",
    "OSB.duty": "职务",
    "OSB.tel": "电话",
    "OSB.mobile": "手机号",
    "OSB.fax": "传真",
    "OSB.Yahoo": "雅虎",
    "OSB.website": "网站",
    "OSB.address": "地址",
    "OSB.postalCode": "邮政编码",
    "OSB.ExistingAccount": "已有账号",
    "OSB.DirectLogin": "直接登录！",
    "OSB.productPrice": "产品价格",
    "OSB.ProductNumber": "产品编号",
    "OSB.Packing": "包装方式",
    "OSB.OuterBoxCBM": "外箱体积",
    "OSB.OuterBoxCUFT": "外箱材积",
    "OSB.Innerboxes": "内盒个数",
    "OSB.OuterQTY": "外箱装量",
    "OSB.GWAndNW": "毛重 / 净重",
    "OSB.productSize": "产品规格",
    "OSB.packageSize": "包装规格",
    "OSB.cartonSize": "外箱规格",
    "OSB.addCart": "添加购物车",
    "OSB.delCart": "取消加购",
    "OSB.product": "产品",
    "OSB.NewsUpdates": "新闻动态",
    "OSB.ReleaseDate": "发布日期",
    "OSB.MyCart": "我的购物车",
    "OSB.ID": "ID",
    "OSB.picture": "图片",
    "OSB.ArticleNo": "货号",
    "OSB.Description": "名称",
    "OSB.CartOuterQTY": "外箱装量",
    "OSB.CBM": "体积",
    "OSB.CTNS": "箱数",
    "OSB.totalQTY": "总数量",
    "OSB.TotalVolume": "总体积",
    "OSB.total": "合计",
    "OSB.delete": "删除",
    "OSB.checkAll": "全选",
    "OSB.clear": "清空",
    "OSB.totalItems": "总款数",
    "OSB.totalCTNS": "总箱量",
    "OSB.noData": "暂无数据",
    "OSB.totalPrice": "总价",
    "OSB.systemTip": "系统提示",
    "OSB.confirm": "确定",
    "OSB.cancel": "取消",
    "OSB.deleteData": "是否删除该条数据？",
    "OSB.clearCartData": "是否清空全部购物车？",
    "OSB.submitTip": "确认提交订单？",
    "OSB.MyOrder": "我的订单",
    "OSB.PleaseOrderNum": "请输入订单号",
    "OSB.number": "单号",
    "OSB.viewDetails": "查看详情",
    "OSB.orderTime": "订单时间",
    "OSB.linkman": "联系人",
    "OSB.sendDate": "发货时间",
    "OSB.expressNumber": "快递单号",
    "OSB.orderStatus": "订单状态",
    "OSB.unshipped": "未发货",
    "OSB.shipped": "已发货",
    "OSB.SiteNavigation": "网站导航",
    "OSB.ContactUs": "联系我们",
    "OSB.FollowUs": "关注我们",
    "OSB.Landline": "座机",
    "OSB.Email": "邮箱",
    "OSB.Phone": "手机",
    "OSB.WeChat": "微信号",
    "OSB.Technic": "技术支持",
    "OSB.Software": "深圳宏升软件技术开发有限公司",
    "OSB.detailBtn": "产品详情",
    "OSB.noDetailBtn": "暂无产品详情",
    "OSB.classify": "产品分类",
    "OSB.exclusive": "专属推荐",
    "OSB.loading": "加载中...",
    "OSB.updateTime": "更新时间",
    "OSB.SearchMsg": "以上搜索条件可选任意一项或多项综合搜索",
    "OSB.MessageTitle": "在线提交您的需求",
    "OSB.MessageMsg": "我们将在24小时内回复您的电子邮件，节假日除外",
    "OSB.MessageName": "你的名字",
    "OSB.MessageTel": "你的手机",
    "OSB.MessageEmail": "你的邮箱",
    "OSB.MessageNeeds": "你的需求",
    "OSB.SendInquiry": "发询价单",
    "OSB.EnquiryList": "询价单",
    "OSB.enquiry": "询价",
    "OSB.MyEnquiryList": "我的询价单",
    "OSB.Remark": "备注",
    "OSB.Enquirys": "请留下您的电子邮件，我们会与您联系",
    "OSB.proClass": "产品分类",


    "OSB.topWelcome": "欢迎访问ING玩具官方网站主页",
    "OSB.searchPro": "搜索...",
    "OSB.NewArrivals": "最新产品",
    "OSB.BestSellers": "热卖产品",
    "OSB.SpecialOffers": "优惠产品",
    "OSB.OurServices": "我们的服务",
    "OSB.OurServicesMsg": "ING玩具的目标是成为全球最强大的玩具供应商。我们的核心优势体现在以下几个方面：1000000多种多样的产品可供选择，高效的服务速度可快速满足市场需求，确保客户不会错过任何商机。",
    "OSB.oemMsg": "通过定制ODM/OEM服务释放品牌潜力",
    "OSB.oemMsg1": "通过低最低起订量（MOQ）服务解锁机会",
    "OSB.oemMsg2": "通过卓越的供应链为您的亚马逊业务赋能",
    "OSB.SupportTitle": "商业支持",
    "OSB.AmazonTitle": "亚马逊支持",
    "OSB.ourMissionTitle": "我们的使命",
    "OSB.ourMissionMsg": "我们在优化分销链流程方面为合作伙伴提供最大支持，以帮助他们在市场上获得竞争优势。",
    "OSB.EASY_SOURCING": "简单搜索",
    "OSB.EASY_Msg1": "发布采购、请求和报价的简单方法。",
    "OSB.EASY_Msg2": "让我们一起创造一个欢笑、学习和玩耍齐头并进的世界。",
    "OSB.proName": "产品名称",
    "OSB.searchPacking": "包装",
    "OSB.QualityControlMsg": "质量是我们的承诺。我们库存的每一款玩具都经过精心制作，符合最高的安全和耐用性标准。放心吧，知道你的笑容会持续很久。",
    "OSB.amazon1.title": "多种自有品牌和包装理念",
    "OSB.amazon1.msg1": "起订量低，预算紧张，在阿里巴巴上很难找到匹配的供应商，这些都会让你很难开发出品牌产品。我们是来帮你的",
    "OSB.amazon1.msg2": "根据现有产品更改材质、尺寸和颜色",
    "OSB.amazon1.msg3": "根据您的三维设计开发全新产品",
    "OSB.amazon1.msg4": "定制包装，从竞争对手中脱颖而出",
    "OSB.amazon1.msg5": "套件和捆绑产品以创造更多销售额",
    "OSB.amazon2.title": "一对一质量检查",
    "OSB.amazon2.titleMsg1": "通过卓越的供应链为您的亚马逊业务赋能",
    "OSB.amazon2.titleMsg2": "您是否是亚马逊卖家，正在寻求可靠的供应链解决方案来推动您的电子商务成功？别再看了！我们专门提供一流的亚马逊供应链服务，旨在简化您的运营并确保库存的无缝流动",
    "OSB.amazon2.titleMsg3": "为什么选择我们的亚马逊供应链服务？",

    "OSB.amazon2.msg1": "作为亚马逊卖家，因为产品质量问题而获得差评可能是致命的。检查公司和供应商可以检查你的20-30%的箱子，但他们只会告诉你结果是失败还是通过。",
    "OSB.amazon2.msg2": "我们只需支付少量的人工费就可以逐一检查您的物品。我们将识别每一个有缺陷的项目，并要求中国工厂进行修复。",
    "OSB.amazon3.title": "低成本亚马逊Fba标签",
    "OSB.amazon3.msg1": "亚马逊FBA标签服务费为每台0.55美元，第三方标签服务费则为每台0.2-0.3美元。我们可以以每小时5美元的价格添加标签，这更具成本效益。标签包括：",
    "OSB.amazon3.msg2": "芬兰语标签",
    "OSB.amazon3.msg3": "运输标签",
    "OSB.amazon3.msg4": "警告标签",
    "OSB.amazon3.msg5": "亚马逊要求的其他标签",
    "OSB.amazon4.title": "在中国免费存储1-2个月",
    "OSB.amazon4.msg1": "亚马逊存储非常昂贵。在旺季，卖家在亚马逊仓库的存储能力有限。为了您的方便，我们在中国提供1-2个月的免费存储。",
    "OSB.amazon4.msg2": "为了降低亚马逊的存储费用，我们可以将您的一些产品存储在我们的仓库中，并在您需要补货时将其分批发送到亚马逊FBA仓库。",
    "OSB.amazon.contact": "今天就与我们合作，让我们的亚马逊供应链服务成为您值得信赖的成功合作伙伴。我们致力于帮助您简化运营，降低成本，并为您的亚马逊客户提供卓越的服务。立即联系我们，开启卓越供应链之旅！”",

    "OSB.partnership1.title": "我们的优势",
    "OSB.partnership1.msg1": "我们了解您的业务动态。凭借极具竞争力的定价和灵活的订购选项，我们是您成功的战略盟友。无论您是零售商、教育工作者还是活动策划人，我们都为您提供保障。",
    "OSB.partnership1.msg2": "无尽的变化",
    "OSB.partnership1.msg3": "无法衡量的质量",
    "OSB.partnership1.msg4": "你的一站式玩具中心",
    "OSB.partnership1.msg5": "传播微笑",
    "OSB.partnership1.msg6": "您的成功伙伴",
    "OSB.partnership1.msg7": "合作伙伴B2B Web服务",
    "OSB.partnership2.title": "合作伙伴B2B Web服务",
    "OSB.partnership2.msg1": "我们是你的终极合作伙伴。从经典奇观到尖端小说，我们精心策划了一个广泛的系列，满足所有人的喜好。",
    "OSB.partnership2.msg2": "走进一个满足每一种想象力的世界。从激发好奇心的教育奇迹到提供舒适感的有趣伙伴，我们的多样化系列为每个孩子的梦想做出了承诺。",
    "OSB.partnership2.msg3": "网上商店",
    "OSB.partnership3.title": "多种联系方式",
    "OSB.partnership3.msg1": "加入我们的行列，让童年记忆更加明亮和难忘。让我们合作，创造一个幸福的世界。",
    "OSB.partnership3.msg2": "手机",
    "OSB.partnership3.msg3": "Whatspp/微信",
    "OSB.partnership3.msg4": "电子邮件",
    "OSB.inquiry.title": "在我们的网站上没有找到你需要的产品吗？",
    "OSB.inquiry.msg1": "让我们建立一个蓬勃发展的合作伙伴关系，你的成功为我们的成功加油。今天就与我们联系，踏上一段相互成长、无限乐趣和非凡潜力的旅程。",
    "OSB.inquiry.form.title": "选择您的采购请求",
    "OSB.inquiry.FirstName": "名字",
    "OSB.inquiry.lastName": "姓氏",
    "OSB.inquiry.Email": "电子邮箱",
    "OSB.inquiry.Phone": "手机号(eg.+12345678)",
    "OSB.inquiry.Address": "装运样品的地址",
    "OSB.inquiry.Company": "公司",
    "OSB.inquiry.Postal/ZipCode": "邮政编码/邮编",
    "OSB.inquiry.Country": "国家",
    "OSB.inquiry.BusinessType": "业务类型",
    "OSB.inquiry.Remark": "告诉我们你需要什么样的产品",
    "OSB.inquiry.Upload": "上传附件",
    "OSB.inquiry.Submit": "提交",

    "OSB.oem.titileMsg": "通过定制ODM/OEM服务释放品牌潜力",
    "OSB.oem.welcome": "你想提升自己的品牌并在市场上脱颖而出吗？别再看了！我们专门提供顶级ODM（原始设计制造商）和OEM（原始设备制造商）服务，可以将您独特的产品理念付诸实践。",
    "OSB.oem1.title1": "ODM 解决方案",
    "OSB.oem1.content1": "通过我们的ODM服务，我们为您提供量身定制的创新设计解决方案，帮助您创造真正反映您品牌身份的产品。我们的专家团队将与您密切合作，开发、设计和制造超出您预期的产品。",
    "OSB.oem1.title2": "OEM 解决方案",
    "OSB.oem1.content2": "如果您已经有了产品概念，我们的OEM服务可以让您利用我们的制造能力生产高质量的定制品牌产品。我们理解保持品牌完整性的重要性，我们的OEM服务确保您的产品符合最高的质量和工艺标准。",
    "OSB.oem2.title": "为什么选择我们的ODM/OEM服务？",
    "OSB.oem2.title1": "定制",
    "OSB.oem2.msg1": "我们相信将您的愿景变为现实。我们的ODM/OEM服务都是关于定制的，确保您的产品是独一无二的，并根据您的品牌量身定制。",
    "OSB.oem2.title2": "质量保证",
    "OSB.oem2.msg2": "我们致力于提供最高质量的产品。我们严格的质量控制流程确保每一款产品都符合行业标准。",
    "OSB.oem2.title3": "效率",
    "OSB.oem2.msg3": "时间就是金钱，我们明白及时交货的重要性。我们精简的流程确保您的产品能够高效生产和交付。",
    "OSB.oem2.title4": "经济高效的解决方案",
    "OSB.oem2.msg4": "我们在不影响质量的情况下提供有竞争力的价格。我们的ODM/OEM服务旨在最大限度地提高您的投资回报率",
    "OSB.oem2.title5": "全球影响力",
    "OSB.oem2.msg5": "我们的业务遍及全球，能够满足全球客户的需求。无论您身在何处，我们都能为您提供所需的服务。",
    "OSB.oem.contact": "今天就与我们合作，通过我们的ODM/OEM服务将您的品牌提升到新的高度。让我们将您的想法转化为市场领先的产品，吸引您的受众并推动成功。立即联系我们，探索无尽的可能性！",


    "OSB.support.titileMsg": "通过低最低起订量（MOQ）服务解锁机会",
    "OSB.support.welcome1": "你是一家初创企业、小企业还是企业家，希望将你的创新产品理念付诸实践，而不必承担高订单量的负担？",
    "OSB.support.welcome2": "别再看了！我们专门提供最低起订量服务，使您能够轻松、经济地将概念变为现实。",
    "OSB.support1.title": "为什么选择我们的最低起订量服务？",
    "OSB.support1.title1": "可访问性",
    "OSB.support1.msg1": "我们的低起订量服务使各种规模的企业都可以进行产品开发。无论您是需要一小批产品来测试市场，还是存储空间有限，我们都会为您提供服务。",
    "OSB.support1.title2": "成本效益",
    "OSB.support1.msg2": "告别过高的库存成本。我们的最低起订量服务使您能够最大限度地减少前期费用并有效管理预算。",
    "OSB.support1.title3": "产品多样性",
    "OSB.support1.msg3": "在不需要大量订单的情况下探索广泛的产品。我们在这里帮助您实现产品多样化。",
    "OSB.support1.title4": "市场灵活性",
    "OSB.support1.msg4": "保持灵活性并对市场趋势作出反应。通过低起订量服务，您可以快速适应不断变化的消费者偏好，并更快地推出新产品。",
    "OSB.support1.title5": "质量保证",
    "OSB.support1.msg5": "无论订单大小，我们都保持着同样的高质量和工艺标准。您的产品将达到行业标准，并超出客户的期望。",
    "OSB.support1.title6": "灵活缩放",
    "OSB.support1.msg6": "随着您业务的发展，我们的服务可以与您一起扩展。从lo无缝过渡",
    "OSB.support.contact": "不要让高起订量降低你的潜力。今天就与我们合作，我们将通过我们的低起订量服务，让您独特的产品理念栩栩如生。体验让我们与众不同的灵活性、可负担性和质量。立即联系我们，开启您的创新和成长之旅！",
    "OSB.myfavorite": '我的收藏夹',
    "OSB.video": '视频',
    "OSB.blog": "博客",
    "OSB.title": "汕头最大的棋盘游戏和DIY玩具工厂之一",
    "OSB.text1": "超过20年的专业经验。",
    "OSB.text2": "提供300多种棋盘游戏和200多种DIY玩具。",
    "OSB.text3": "OEM服务已使3000多名客户受益。",
    "OSB.learnmoreabout": "了解更多",
    "OSB.exportingtoys": "一家有7年玩具出口经验的贸易公司",
    "OSB.wsmxzwm": "为什么选择我们",
    "OSB.ToysManufacturer": "从中国玩具制造商进口玩具",
    "OSB.OEM": "原设备制造商",
    "OSB.OEMcontent": "支持定制包装、徽标、开发模具和开发自己的独家品牌。",
    "OSB.guide": "汕头玩具市场指南",
    "OSB.guidecontent": "10000+㎡ 展厅，600多万件玩具。世界上最大的玩具制造基地。",
    "OSB.office": "您在中国的采购办事处",
    "OSB.officecontent": "进口的不仅仅是玩具？我们的专业采购部门伙伴可以提供帮助。",
    "OSB.business_support": "亚马逊卖家支持",
    "OSB.business_supportcontent": "小批量最小起订量、检验和质量控制（100%guaratee）、免费储存",
    "OSB.sellersupport": "趋势分析报告",
    "OSB.sellersupportcontnet": "每月更新最新的产品趋势和报告。帮助您保持领先地位",
    "OSB.inquiry": "提交您的查询",
    "OSB.inquirycontent": "24小时在线，今天开始与Osanbo一起成长。立即采取行动并与我们联系。",
    "OSB.htzz": "海天之藻",
    "OSB.serviceexperience": "海天智造“提交您的咨询。根据您的疑问，我们将为您提供一对一的专业顾问，解答您的疑问并根据您的咨询探索您的真实需求。让您拥有更好的服务体验。",
    "OSB.amplerequest": "只需输入产品编号、产品名称、分类、价格范围和包装方法，即可在1秒内找到您需要的产品。",
    "OSB.ReadyChina": "准备好从中国进口了吗？",
    "OSB.Wecanhelpyou": "今天就用欧尚宝玩具开始成长吧。我们可以帮助您以最具竞争力的价格批发或定制产品。",
    "OSB.competitiveprices": "以最具竞争力的价格批发或定制产品。",
    "OSB.ClientsNow": "立即加入我们的5000多名客户",
    "OSB.Contact": "联系",
    "OSB.followus": "关注我们",
    "OSB.keywords": "请输入关键字",
    "OSB.Hearbest": "5000多名客户信任欧尚宝",
    "OSB.evaluations": "我们在世界各地都有各种业务的客户。听听他们对我们的评价。",
    "OSB.showroom": "点击进入我们的在线展厅，您需要的都在这里",
    "OSB.toys": "1万多平方米展厅, 600多万件玩具, 4千以上客户在此选择玩具",
    // "OSB.toyshere": "4千以上客户在此选择玩具",
    "OSB.seeshowroom": "查看在线展厅",
    "OSB.PleaseLogin": "请登录",
    "OSB.last": "上个",
    "OSB.next": "下个",
    "OSB.addtofavorite": "添加到收藏夹",
    "OSB.Business": "你想提升你的业务吗？",
    "OSB.marketguide": "汕头市场引导",
    "OSB.purchaseoffice": "办公地",
    // "OSB.sellersupport": "亚马逊卖家支持",
    "OSB.Submityourinquiry": "提交需求",
    "OSB.tradingbusiness1": "2015年，开始玩具贸易业务",
    "OSB.tradingbusiness2": "我们的采购部门在奇纳为客户做采购代理。",
    "OSB.tradingbusiness3": "（汕头、广州、义乌、宁波等地）",
    "OSB.tradingbusiness4": "跟进所有订单以节省时间和成本。",
    "OSB.followup": "跟进所有订单以节省时间和成本。",
    "OSB.Freesoucing1": "免费采购",
    "OSB.Freesoucingtxt1": "采购产品以满足您的市场需求，在您来中国之前找到可靠的工厂。因此，你可以在任何时候做出自己的决定，节省在中国的时间。",
    "OSB.Freesoucing2": "来自中国各地多家工厂的整合",
    "OSB.Freesoucingtxt2": "协助收集来自中国各地的货物，并将其集中在同一地点进行集装箱装载。节省联系供应商的时间，提高效率。",
    "OSB.Freesoucing3": "研发",
    "OSB.Freesoucingtxt3": "如果你找不到你想要的产品，我们将帮助你发展自己的想法。让它从定义变成实际。艺术品设计/根据客户需求制作包装/条形码读取测试/抽样和产品测试（TUV、SGS、BV等）",
    "OSB.Freesoucing4": "差旅安排和筹备",
    "OSB.Freesoucingtxt4": "邀请函/酒店最佳折扣和VIP服务/机场接送。根据你的时间表制定计划，帮助你节省成本和时间。",
    "OSB.Freesoucing5": "检验和QC（100%保证）",
    "OSB.Freesoucingtxt5": "专业的QC团队将为您进行严格的检查，100%确保所有产品与您的订单相同。",
    "OSB.Freesoucing6": "仓储和重新包装",
    "OSB.Freesoucingtxt6": "提供免费仓储服务，如果您想更改包装，我们将按照您的指示进行。",
    "OSB.Freesoucing7": "物流和文件",
    "OSB.Freesoucingtxt7": "我们与大多数最大的航运公司合作，可以将您的货物运送到世界任何地方。将提供相关文件，如PL、BL、CI等。",
    "OSB.getstarted": "开始",
    "OSB.inquiry.title": "提交您的采购申请",
    "OSB.inquiry.msg": "请填写此表格，填写您的详细需求，我们的客户支持团队将很快与您联系。我们将指派专业人员跟进您的项目并提供个性化帮助。为了获得最快的回复，请使用表格提交您的查询。如果您在提交时遇到任何问题，您也可以直接发送电子邮件至sales@osanbotoys.com.",
    "OSB.server.showroom": "10000+㎡ 展厅，600多万件玩具",
    "OSB.server.showroomTitle": "查看视频下方以了解其工作原理",
    "OSB.server.showroomtext1title": "全流程导购、商务洽谈办公场地, ",
    "OSB.server.showroomtext1": "与工厂沟通对接——0费用！",
    "OSB.server.showroomtext1box": "专车接送、工作餐饮等——0收费！",
    "OSB.server.showroomtext2title": "各类展位25000多个：",
    "OSB.server.showroomtext2": "益智玩具、桌游、电动玩具、芭比娃娃、厨房玩具、充气玩具、积木、遥控玩具等。",
    "OSB.server.showroomtext3": "直接会见25000+工厂。",
    "OSB.server.showroomtext4title": "高效快速挑选最新畅销玩具。",
    "OSB.server.showroomtext4": "与工厂面对面洽谈以获得最优惠的价格。",
    "OSB.server.showroomtext4box": "常年一站式玩具采购。",
    "OSB.server.suppliers1": "由于最低起订量低、预算紧张以及难以找到匹配的供应商，难以开发您的品牌产品？",
    "OSB.server.supplierstext1": "别再看了！我们是来帮忙的。我们可以根据现有产品更改材料、尺寸和颜色，根据您的3D设计开发全新产品，定制包装以脱颖而出。",
    "OSB.server.suppliers2": "厌倦了因为产品质量问题在亚马逊上收到差评吗？",
    "OSB.server.supplierstext2": "我们进行一对一的质量检查，提供解决方案。我们仔细检查每一件物品，找出每一个有缺陷的。与中国工厂沟通，以解决问题。",
    "OSB.server.suppliers3": "享受我们的标签服务",
    "OSB.server.supplierstext3": "包括亚马逊要求的所有必要标签，如FNSKU标签、运输标签、警告标签以及平台要求的任何其他特定标签。",
    "OSB.server.suppliers4": "在中国免费存储2个月。",
    "OSB.server.supplierstext4": "亚马逊存储非常昂贵。在旺季，卖家在亚马逊仓库的存储能力有限。为了您的方便，我们在中国提供2个月的免费存储。",
    "OSB.server.oem1": "找不到您想要的产品。",
    "OSB.server.oem2": "我们将帮助您发展自己的想法。",
    "OSB.server.oem3": "让它从定义变成实际。",
    "OSB.server.development1": "包装开发",
    "OSB.server.development2": "模具开发",
    "OSB.server.mouldbrand1": "客户确认要定制哪种产品？",
    "OSB.server.mouldbrand2": "Osanbo为客户提供空白模切艺术品，以设计他们的包装。",
    "OSB.server.mouldbrand3": "收到定制艺术品和样品费后，Osanbo将制作数字打印样品并发送确认。",
    "OSB.server.mouldbrand4": "在收到并检查样品后，如果需要进行任何调整，Osanbo将重新制作样品并重新发送确认。",
    "OSB.server.mouldbrand5": "客户确认最终样品、价格、订单数量。",
    "OSB.server.mouldbrand6": "签署协议并联系",
    "OSB.server.mouldtab1": "定制您自己的包装的6个步骤如下",
    "OSB.server.molddevelopment1": "客户向我们发送设计的侧视图、俯视图。",
    "OSB.server.molddevelopmenttext1": "或对形状、大小的描述。或者向Osanbo提供参考样品。提供包装图、标签图、唛头的包装规格（显示每个内纸箱和外纸箱的数量）。告知预计订单数量。",
    "OSB.server.molddevelopment2": "Osanbo根据产品工艺图计算产品成本。",
    "OSB.server.molddevelopmenttext2": "告知3D设计、3D样品成本和时间、样品费用和时间、产品单价和预计交付周期。",
    "OSB.server.molddevelopment3": "与客户达成价格协议并收取样品费",
    "OSB.server.molddevelopmenttext3": "Osanbo将开始进行3D设计，并发送3D艺术品进行确认。",
    "OSB.server.molddevelopment4": "在艺术品获得批准后，Osanbo制作3D打印样品或泥浆模型、数字打印盒子和标签，并通过DHL/UPS/FEDEX包装和发送完整的样品以进行确认。",
    "OSB.server.molddevelopment5": "第一个样品鉴定后，客户安排TUV/SGS/BV测试",
    "OSB.server.molddevelopment6": "通过测试，签订协议和合同。",
    "OSB.server.officehelp": "我们能提供什么帮助呢",
    "OSB.server.officebox1": "采购产品以满足您的市场需求，找到可靠的工厂。",
    "OSB.server.officebox2": "来自中国各地多家工厂的整合",
    "OSB.server.officebox3": "协助发展你自己的想法。让它从定义变成实际。",
    "OSB.server.officebox4": "邀请函/酒店最佳折扣和VIP服务/机场接送。根据时间表制定计划。",
    "OSB.server.officebox5": "检验和QC（100%保证）",
    "OSB.server.officebox6": "我们做的比你想象的要多。。",
    "OSB.server.purchasetext1": "您在中国的采购办事处",
    "OSB.server.purchasetext2": "如此方便和快乐就像在自己的公司工作一样。您在中国的一站式采购办公室。",
    "OSB.server.howwework": "我们如何工作？",
    "OSB.server.wholesale": "中国所有产品批发市场",
    "OSB.myfavoriteon": "我的收藏",
    "OSB.ourpricing": "我们的定价和计划",
    "OSB.innerbox": "内箱数量",
    "OSB.outerbox": "外箱数量",
    "OSB.aboutus": "关于我们"
}
