<!--
 * @Description: 帮助中心
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-09-19 14:02:56
-->
<template>
  <div>
    <component :datas="contactData" :is="componentName"></component>
  </div>
</template>
<script>
import { GetHelptInfo } from '@/api/home'
import mixins from '@/utils/mixins'
export default {
  name: 'services',
  mixins: [mixins],
  data() {
    return {
      contactData: {},
      componentName: '',
    }
  },
  created() {
    this.init()
    this.initDom()
  },
  methods: {
    initDom() {
      switch (this.$templates()) {
        case 'themeTemplateSix':
          this.componentName = 'pagesellersupportSix'
          break
        default:
          this.componentName = ''
          break
      }
    },
    init() {
      GetHelptInfo().then((res) => {
        if (res.success) {
          this.contactData = res.data
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
</style>