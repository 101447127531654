<!--
 * @Description: 
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-07-21 09:41:02
-->
<template>
  <div class="cart-box">
    <component :is="componentName"></component>
  </div>
</template>

<script>
import mixins from '@/utils/mixins'
export default {
  name: 'cartPage',
  mixins: [mixins],
  data() {
    return {
      componentName: ''
    }
  },
  created() {
    this.initDom()
  },
  methods: {
    initDom() {
      switch (this.$templates()) {
        case 'themeTemplateOne':
          this.componentName = 'pageCartOne'
          break
        case 'themeTemplateTow':
          this.componentName = 'pageCartTow'
          break
        case 'themeTemplateThree':
          this.componentName = 'pageCartThree'
          break
        case 'themeTemplateFour':
          this.componentName = 'pageCartFour'
          break
        case 'themeTemplateFive':
          this.componentName = 'pageCartFive'
          break
        case 'themeTemplateSix':
          this.componentName = 'pageCartSix'
          break
        case 'themeTemplateSeven':
          this.componentName = 'pageCartSeven'
          break
        default:
          this.componentName = ''
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.cart-box {
  width: 100%;
  // background: #f1f3f6;
}
</style>